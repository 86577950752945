<template>
  <b-sidebar right v-model="show" class="sidebar-search-new-version" shadow backdrop @change="hasClosedSidebar" ref="sidebar_search">
    <template #header>
      <div class="w-100 d-flex justify-content-between align-items-center px-1">
        <h5 class="m-0">{{ $t('search_preview.profileInfluencer') }}</h5>
        <feather-icon icon="XIcon" class="cursor-pointer" size="20" @click="show = false"></feather-icon>
      </div>
    </template>
    <b-row class="m-0 overflow-none" v-if="loaded_service">
      <b-col class="col-4 px-2 border-bottom-sidebar" v-if="data_user && data_user.parent_data">
        <div class="d-flex flex-column align-items-center w-100">
          <b-avatar
            :src="network === 'instagram' ? data_user.parent_data.username.name.ava : data_user.parent_data.username.name.avatar"
            class="p-0 mb-2 avatar-sidebar-search"
          ></b-avatar>
          <h4 class="text-capitalize text-underline cursor-pointer">{{ data_user.parent_data.username.name.fullname }}</h4>
          <p class="text-muted-left-side-analytics">{{ getFormat(data_user.parent_data.followers)}} {{ $t('search_preview.totalAudience') }}</p>
          <p class="text-muted-left-side-analytics mb-1" v-if="data_user.features && data_user.features.blogger_geo && data_user.features.blogger_geo.data">{{ data_user.features.blogger_geo.data.country }}</p>
        
          <b-dropdown variant="blue-button text-white" :text="`${$t('search.add_to')}...`" menu-class="w-100 p-0" class="w-100 blue-button text-white dropdown-add-influencer-action">
            <b-dropdown-header>
              <div v-if="!show_add_to.list && !show_add_to.campaign">
                <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search.add_to') }}</p>
                <b-button-group vertical class="w-100 button-group-add-influencer-action">
  
                  <b-button @click.stop="show_add_to.list = true">
                    <div class="d-flex align-items-center"><feather-icon icon="ListIcon" class="img-icon-action mr-1"></feather-icon> {{$t('search.list_view')}}</div>
                    <feather-icon icon="ChevronRightIcon" class="img-icon-action"></feather-icon>
                  </b-button>
                  <b-button @click.stop="show_add_to.campaign = true">
                    <div class="d-flex align-items-center">
                      <b-img :src="require('@/assets/images/svg/campaign_int_black.svg')" class="img-icon-action mr-1"></b-img> {{ $t('search.campaign')}}
                    </div>
                    <feather-icon icon="ChevronRightIcon" class="img-icon-action"></feather-icon>
                  </b-button>
                </b-button-group>
              </div>
              <div v-if="show_add_to.list">
                <div class="p-1 border-bottom cursor-pointer" @click.stop="show_add_to.list = false">
                  <h5 class="f-16"><feather-icon icon="ArrowLeftIcon" size="20"></feather-icon> {{ $t('search_preview.addList') }}</h5>
                </div>
                <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search_preview.allList') }}</p>
                <div v-if="!loaded_service_add" class="p-1">
                  <b-skeleton class="p-1"></b-skeleton>
                </div>
                <div class="p-1 container-options-list-ul" v-else>
                  <div
                    v-for="(list, index) in lists" 
                    :key="index" 
                    :class="`avenir-medium w-100 dropitem-add-campaign ${list.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
                    @click="list.with_influencer ? '' : addToList(list.uuid, index)"
                  >
                    <div class="d-flex justify-content-between container-drop-items">
                      <span class="d-block"> {{list.object_name.substr(0, 25)}}</span>
                      <feather-icon 
                        :icon="list.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                        :class="list.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
                      </feather-icon>
                      <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-${index}`"></b-spinner>
                    </div>
                  </div>
                  <div v-if="lists.length === 0" class="avenir-medium p-0">
                    {{ $t('lists.noLists') }}
                  </div>
                </div>
              </div>
              <div v-if="show_add_to.campaign">
                <div class="p-1 border-bottom cursor-pointer" @click.stop="show_add_to.campaign = false">
                  <h5 class="f-16"><feather-icon icon="ArrowLeftIcon" size="20"></feather-icon> {{ $t('search_preview.addCampaign') }}</h5>
                </div>
                <p class="text-uppercase color-gray-title-add m-0 p-1">{{ $t('search_preview.allCampaigns') }}</p>
                <div v-if="!loaded_service_add" class="p-1">
                  <b-skeleton class="p-1"></b-skeleton>
                </div>
                <div class="p-1 container-options-list-ul" v-else>
                  <div
                    v-for="(campaign, index) in campaigns" 
                    :key="index" 
                    :class="`avenir-medium w-100 dropitem-add-campaign ${campaign.with_influencer ? 'dropitem-add-campaign-disabled' : ''}`" 
                    @click="campaign.with_influencer ? '' : addToCampaign(campaign.uuid, index)"
                  >
                    <div class="d-flex justify-content-between container-drop-items">
                      <span class="d-block"> {{campaign.object_name.substr(0, 25)}}</span>
                      <feather-icon 
                        :icon="campaign.with_influencer ? 'CheckCircleIcon' : 'PlusIcon'" 
                        :class="campaign.with_influencer ? 'success-color-icon' : 'secondary-color-icon'">
                      </feather-icon>
                      <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-c-${index}`"></b-spinner>
                    </div>
                  </div>
                  <div v-if="campaigns.length === 0" class="avenir-medium p-0">
                    {{ $t('campaigns.noCampaigns') }}
                  </div>
                </div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </div>
      </b-col>
      <b-col class="col-8 background-small p-0" v-if="data_user && !error_data">
        <div class="box-expired-report" v-if="states_inform.has_expired">
          <h5>{{ $t('search_preview.reportExpired') }}</h5>
          <p class="avenir-medium">{{ $t('search_preview.accessFor') }} {{ states_inform.valid_profile }} {{ $t('preview.days') }}</p>
          <b-button class="blue-button" variant="blue-button" @click.once="getProfileUrl()">{{ $t('lists.update') }}</b-button>
        </div>
        <div class="p-1">
          <div class="grid-container mb-2">
            <div class="grid-item" v-for="(image, index) in getImagesContent()" :key="index">
              <img :src="image.basic.preview_url" class="img-cover"/>
              <div class="container-icon-heart">
                <p class="d-flex align-items-center" v-if="image.metrics.likes_count">
                  <feather-icon icon="HeartIcon" class="mr-05"></feather-icon>
                  <span>{{ getFormat(image.metrics.likes_count) }}</span>
                </p>
              </div>
            </div>
          </div>
  
          <div class="grid-analytics mb-2">
            <div class="grid-item box-style-analytics">
              <span class="avenir-medium">ER</span>
              <span class="title-result">{{ data_user.metrics.er.value.toFixed(2)}}%</span>
            </div>
  
            <div class="grid-item box-style-analytics">
              <span class="avenir-medium">{{ $t('search_preview.avgLikes') }}</span>
              <span class="title-result">{{ data_user.metrics.likes_avg.value ? getFormat(data_user.metrics.likes_avg.value) : 0}}</span>
            </div>
  
            <div class="grid-item box-style-analytics">
              <span class="avenir-medium">{{ $t('search_preview.reach') }}</span>
              <span class="title-result">{{ !Array.isArray(data_user.features.blogger_reach) && data_user.features.blogger_reach.data.reach ? getFormat(data_user.features.blogger_reach.data.reach) : 'N/D'}}</span>
            </div>
  
            <div class="grid-item box-style-analytics">
              <span class="avenir-medium">{{ $t('search_preview.estimedPrice') }}</span>
              <span class="title-result">{{ data_user.features.blogger_prices.data && data_user.features.blogger_prices.data.post_price ? `$${getFormat(data_user.features.blogger_prices.data.post_price)}` : 'N/D'}}</span>
            </div>
          </div>
  
          <div class="box-style-analytics mb-2">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="f-12 m-0">
                <span class="text-uppercase text-blue text-underline cursor-pointer" @click="redirectToSocialNet(data_user.basic.username)">@{{data_user.basic.username }}</span>
                · {{ getFormat(data_user.parent_data.followers) }} <span class="text-uppercase"> {{ $t('campaigns.followers') }}</span>
              </p>
              <b-button variant="outline-secondary-custom-analysis" @click="redirectToSocialNet(data_user.basic.username)" class="outline-secondary-custom-analysis">
                <feather-icon icon="ExternalLinkIcon"></feather-icon>
              </b-button>
            </div>
  
            <h4>{{ data_user.basic.title }}</h4>
            <p class="avenir-medium border-bottom pb-1">{{ data_user.basic.description }}</p>
  
            <p class="text-uppercase f-12">{{ $t('search_preview.contentCategory') }}</p>
  
            <div class="grid-categories">
              <div v-for="(category, index) in data_user.parent_data.categories.all_categories.slice(0, 6)" :key="index" class="avenir-medium d-flex flex-column align-items-center text-center">
                <b-img :src="getIconInterest(category)" class="img-grid-categorie" v-if="getIconInterest(category)"></b-img>
                <b-icon :icon="getCategoryIconSearcher(category)" v-else class="icon-b"></b-icon>
                <span class="mt-1 d-block">
                  {{ category }}
                </span>
              </div>
            </div>
            <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="getProfileUrl()">
              <span v-if="!states_inform.has_bought || states_inform.has_expired">
                {{ $t('search_preview.unlockReport') }}
                <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
              </span>
              <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
            </b-button>
          </div>
  
          <div class="box-style-analytics mb-2">
            <p class="text-uppercase f-12">{{ $t('twitter.engagementRate')}}</p>
            <div class="d-flex mb-1" v-if="data_user.metrics.er.mark !== 'none'">
              <h2 class="m-0">{{ data_user.metrics.er.value.toFixed(2)}}%</h2>
              <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).text }}</b-badge>
            </div>
            <h2 v-else>N/D</h2>
            <p class="avenir-medium" v-if="data_user.metrics.er.mark">
              <span v-if="data_user.metrics.er.mark === 'good' || data_user.metrics.er.mark === 'excellent'">{{ $t('search_preview.engagementbest') }} {{ data_user.metrics.er.prc_better }} %</span>
              {{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).long}}
            </p>
            <scale-rate-search v-if="data_user.metrics.er.mark && data_user.metrics.er.mark !== 'none'" :mark="data_user.metrics.er.mark"></scale-rate-search>

          </div>
  
          <div class="box-style-analytics mb-2">
            <div class="d-flex justify-content-between">
              <p class="text-uppercase f-12">{{ $t('search_preview.estimedReach') }}</p>
  
              <div class="toggle-button-post-history">
                <b-button :class="{'active-button': showing_post_data}" @click="showing_post_data = true">{{ $t('search_preview.post') }}</b-button>
                <b-button :class="{'active-button': !showing_post_data}" @click="showing_post_data = false">{{ $t('instagram.History') }}</b-button>
              </div>
            </div>
  
            <!-- POST DATA -->
            <div class="d-flex mb-1 flex-wrap" v-if="!Array.isArray(data_user.features.blogger_reach) && showing_post_data">
              <h2 class="m-0">{{ getFormat(data_user.features.blogger_reach.data.reach_from) }} - {{ getFormat(data_user.features.blogger_reach.data.reach_to) }}</h2>
              <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.reach_mark}`).text }}</b-badge>
              <p class="avenir-medium d-block w-100 mt-1">
                <span>{{ $t('search_preview.publish_seen') }} {{ data_user.calculated_metrics.prc_post_estimated_reach }} %</span>
                {{ $t(`search_preview.reach_estimed.${data_user.features.blogger_reach.data.reach_mark}`)}}
              </p>
            </div>
  
            <!-- HISTORY DATA -->
            <div class="d-flex mb-1 flex-wrap" v-if="!Array.isArray(data_user.features.blogger_reach) && !showing_post_data">
              <h2 class="m-0">{{ getFormat(data_user.features.blogger_reach.data.stories_reach_from) }} - {{ getFormat(data_user.features.blogger_reach.data.stories_reach_to) }}</h2>
              <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.stories_reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.stories_reach_mark}`).text }}</b-badge>
              <p class="avenir-medium d-block w-100 mt-1">
                <span>{{ $t('search_preview.histories_seen') }} {{ data_user.calculated_metrics.prc_story_estimated_reach }} %</span>
                {{ $t(`search_preview.reach_estimed.${data_user.features.blogger_reach.data.reach_mark}`)}}
              </p>
            </div>
  
            <div v-else-if="Array.isArray(data_user.features.blogger_reach)" class="mb-1">
              <h2>N/D</h2>
              <p class="avenir-medium">{{ $t(`search_preview.reach_estimed.none`)}}</p>
            </div>
  
            <div class="grid-inner-box">
              <div class="inner-box">
                <p class="avenir-medium">{{ $t('search_preview.avgLikes') }}</p>
                <h4>{{ data_user.metrics.likes_avg.value ? getFormat(data_user.metrics.likes_avg.value) : 0 }}</h4>
              </div>
              
              <div class="inner-box">
                <p class="avenir-medium">{{ $t('preview.avg_comments') }}</p>
                <h4>{{ data_user.metrics.comments_avg.value ? getFormat(data_user.metrics.comments_avg.value) : 0 }}</h4>
              </div>
  
              <div class="inner-box">
                <p class="avenir-medium">{{ $t('search.averageViewsTiktok')}}</p>
                <h4>{{ data_user.metrics.short_video_views_avg.value ? getFormat(data_user.metrics.short_video_views_avg.value) : 0 }}</h4>
              </div>
  
              <div class="inner-box">
                <p class="avenir-medium">{{ $t('search_preview.avgPost') }}</p>
                <div class="d-flex justify-content-between align-items-center">
                  <h4 class="m-0">{{ data_user.metrics.media_per_week.value ? getFormat(data_user.metrics.media_per_week.value) : 0 }}</h4>
                  <b-badge :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.media_per_week.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.media_per_week.mark}`).text }}</b-badge>
                </div>
              </div>            
            </div>
          </div>
  
          <div class="box-style-analytics mb-2" v-if="data_user.features.aqs">
            <p class="text-uppercase f-12">{{ $t('tiktok.aqs_title') }}</p>
            <div class="d-flex mb-1" v-if="data_user.features.aqs.data.mark !== 'none'">
              <h2 class="m-0">{{ data_user.features.aqs.data.value }} / 100 </h2>
              <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.aqs.data.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.aqs.data.mark}`).text }}</b-badge>
            </div>
            <p class="avenir-medium" v-if="data_user.features.aqs.data.mark === 'good' || data_user.features.aqs.data.mark === 'excellent' || data_user.features.aqs.data.mark === 'very_good'">
              <span>{{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`).first }} {{ data_user.features.aqs.data.prc_better }}%</span>
              {{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`).second}}.
            </p>
            <p v-else class="avenir-medium">{{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`) }}</p>

            <scale-rate-search v-if="data_user.features.aqs.data.mark && data_user.features.aqs.data.mark !== 'none'" :mark="data_user.features.aqs.data.mark === 'very_good' ? 'high' : data_user.features.aqs.data.mark" :extra_key="'1'"></scale-rate-search>

          </div>
  
          <div class="box-style-analytics mb-2">
            <p class="text-uppercase f-12">{{ $t('search_preview.growthAccount') }}</p>
            <div class="mb-1" v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark !== 'none'">
              <div class="d-flex mb-1">
                <h2 class="m-0">{{ data_user.metrics.subscribers_growth_prc.performance['365d'].value.toFixed(2)}}%</h2>
                <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).text }}</b-badge>
              </div>
              <p class="avenir-medium">
                <span v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark === 'average'">{{ $t(`search_preview.suscribers_growth.average`) }}</span>
                <span v-else>{{ $t(`search_preview.suscribers_growth.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`) }} {{ data_user.metrics.subscribers_growth_prc.performance['365d'].similar.toFixed(2)  }}%</span>
              </p>
            </div>
            <div v-else>
              <h2>N/D</h2>
              <p class="avenir-medium">{{  $t(`search_preview.suscribers_growth.none`) }}</p>
            </div>
          
            <div class="mt-2" v-if="subscribers_growth_prc_graph">
              <profile-crecimiento :audienceData="{followers_chart: this.subscribers_growth_prc_graph, following_chart: []}" :show_following="false"></profile-crecimiento>
            </div>
          </div>
  
          <div class="box-style-analytics mb-2">
            <div class="d-flex justify-content-between">
              <p class="text-uppercase f-12">{{ $t('search_preview.priceRendiment') }}</p>
  
              <div class="toggle-button-post-history" v-if="data_user.features.blogger_prices.data">
                <b-button :class="{'active-button': showing_post_data_prices}" @click="showing_post_data_prices = true">{{ $t('search_preview.post') }}</b-button>
                <b-button :class="{'active-button': !showing_post_data_prices}" @click="showing_post_data_prices = false">{{ $t('instagram.History') }}</b-button>
              </div>
            </div>
  
            <!-- POST DATA -->
            <div class="d-flex mb-1 flex-wrap" v-if="data_user.features.blogger_prices.data && showing_post_data_prices">
              <h2 class="m-0">${{ getFormat(data_user.features.blogger_prices.data.post_price_from) }} - ${{ getFormat(data_user.features.blogger_prices.data.post_price_to) }}</h2>
              <!-- <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).text }}</b-badge> -->
              <p class="avenir-medium d-block w-100 mt-1">
                <span>{{ $t('search_preview.price.a') }} {{ data_user.features.blogger_prices.data.cpe.toFixed(2) }} %</span>
                {{ $t('search_preview.price.cpe')}}.
              </p>
            </div>
  
            <!-- HISTORY DATA -->
            <div class="d-flex mb-1 flex-wrap" v-if="data_user.features.blogger_prices.data && !showing_post_data_prices">
              <h2 class="m-0">${{ getFormat(data_user.features.blogger_prices.data.stories_price_from) }} - ${{ getFormat(data_user.features.blogger_prices.data.stories_price_to) }}</h2>
              <!-- <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.stories_reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.stories_reach_mark}`).text }}</b-badge> -->
              <p class="avenir-medium d-block w-100 mt-1">
                <span>{{ $t('search_preview.price.a') }} {{ data_user.features.blogger_prices.data.cpe.toFixed(2) }} %</span>
                {{ $t('search_preview.price.cpe')}}.
              </p>
            </div>
  
            <div v-else-if="!data_user.features.blogger_prices.data" class="mb-1">
              <h2>N/D</h2>
              <p class="avenir-medium">{{ $t(`search_preview.price.none`)}}</p>
            </div>
  
            <div v-if="!Array.isArray(data_user.calculated_metrics.price_affected_by)">
              <p class="text-uppercase f-12 border-top pt-1">{{ $t('search_preview.factorsAffect') }}:</p>
              <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.country">
                <feather-icon :icon="data_user.calculated_metrics.price_affected_by.country.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
                <p>
                  <span>{{ $t(`search_preview.price.country.creator_from`)}} </span>
                  <span>{{ data_user.calculated_metrics.price_affected_by.country.data.country }}</span>
                  <span>{{ $t(`search_preview.price.country.${data_user.calculated_metrics.price_affected_by.country.affect}`)}} </span>
                </p>
              </div>
    
              <div class="avenir-medium d-flex align-items-start justify-content-between mb-1" v-if="data_user.calculated_metrics.price_affected_by.quality_audience">
                <feather-icon :icon="data_user.calculated_metrics.price_affected_by.quality_audience.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
                <p><span>{{ $t(`search_preview.price.audienceAuthenticity.${data_user.calculated_metrics.price_affected_by.quality_audience.affect}`)}} </span></p>
              </div>
    
              <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.er">
                <feather-icon :icon="data_user.calculated_metrics.price_affected_by.er.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
                <p>
                  <span v-if="data_user.calculated_metrics.price_affected_by.er.affect === 'positive'">{{ $t(`search_preview.price.er.positive_first`)}} {{ data_user.calculated_metrics.price_affected_by.er.data.prc_better }}% {{ $t(`search_preview.price.er.positive_second`) }}</span>
                  <span v-else>{{ $t(`search_preview.price.er.negative`) }}</span>
                </p>
              </div>
    
              <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.ad_post_frequency">
                <feather-icon :icon="data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
                <p><span>{{ $t(`search_preview.price.advertisingPost.${data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect}`)}} </span></p>
              </div>
            </div>
  
          </div>
  
          <div class="box-style-analytics mb-2">
            <p class="text-uppercase f-12">{{ $t(`search_preview.mentions180`)}}</p>
            <div class="mb-1" v-if="mentions_user">
              <div class="d-flex mb-1">
                <h2 class="m-0">{{ separatebycomma(mentions_user.summary.ad.posts_count) }}</h2>
                <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).color}`">
                  {{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).mark }}
                </b-badge>
              </div>
              <p class="avenir-medium">
                <span>{{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).long }}</span>
              </p>
            </div>
            <div v-else>
              <h2>N/D</h2>
              <p class="avenir-medium">{{  $t(`search_preview.mentions_text_mark.none`) }}</p>
            </div>
  
            <b-avatar-group size="44px" class="mt-2">
              <b-avatar v-for="(avatar, index) in mentions_avatares.slice(0, 9)" :key="index" :src="avatar"></b-avatar>
              <b-avatar v-if="mentions_avatares.length > 9" :text="`+${mentions_avatares.length - 9}`" class="backgroud-gray-light-avatar"></b-avatar>
            </b-avatar-group>
        
          </div>
  
          <div class="box-style-analytics mb-2">
            <p class="text-uppercase f-12">{{ $t(`search_preview.moreInfoReport`)}}...</p>
  
            <div class="grid-more-info">
              <div class="avenir-medium d-flex align-items-start" v-for="p in more_info" :key="p">
                <feather-icon icon="LockIcon" class="icon-arrows-prices"></feather-icon>
                <p>{{ p }}</p>
              </div>
            </div>
  
            <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="getProfileUrl()">
              <span v-if="!states_inform.has_bought || states_inform.has_expired">
                {{ $t('search_preview.unlockReport') }}
                <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
              </span>
              <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
            </b-button>
          </div>
        </div>
      </b-col>

      <b-col v-else-if="error_data" class="col-8 background-small py-1">
        <div class="d-flex block-emtpy-data align-items-center">
          <div class="container-icon-lock">
            <feather-icon icon="LockIcon" class="icon-lock"></feather-icon>

          </div>
          <h5 class="m-0 text-center w-100">{{ $t('search_preview.accountDenied') }}</h5>
        </div>
      </b-col>
    </b-row>
    <b-row v-else class="m-0 overflow-none">
      <b-col class="col-4 px-2 border-bottom-sidebar">
        <div class="d-flex flex-column align-items-center">
          <b-skeleton class="avatar-sidebar-search"></b-skeleton>
          <b-skeleton class="col-9 mb-02"></b-skeleton>
          <b-skeleton class="col-6 mb-02"></b-skeleton>
          <b-skeleton class="col-12 mt-2 p-1"></b-skeleton>
        </div>
      </b-col>

      <b-col class="col-8 background-small py-1">
        <div class="grid-container mb-2 min-height-281">
          <div class="grid-item" v-for="(index) in 5" :key="index">
            <b-skeleton class="w-100 h-100"></b-skeleton>
          </div>
        </div>

        <div class="grid-analytics mb-2">
          <div class="grid-item min-height-116" v-for="(index) in 4" :key="index">
            <b-skeleton class="w-100 h-100"></b-skeleton>
          </div>
        </div>

        <div class="mb-2 min-height-321">
          <b-skeleton class="w-100 h-100"></b-skeleton>
        </div>

        <div class="mb-2 min-height-232">
          <b-skeleton class="w-100 h-100"></b-skeleton>
        </div>
      </b-col>

    </b-row>
  </b-sidebar>
</template>

<script>
import { 
  BSidebar, 
  BRow, 
  BCol, 
  BAvatar,
  BAvatarGroup,
  BBadge,
  BImg,
  BButton,
  BButtonGroup,
  BSkeleton,
  BDropdown,
  BSpinner,
  BDropdownHeader,
} from "bootstrap-vue";
import search_services, { getSummaryInfluencer, getHistorySuscribers } from '@/services/search';
import lists_services from '@/services/lists'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getFormat, checkNumber, separatebycomma } from "@/libs/utils/formats";
import { getCategoryIconSearcher, getIconInterest } from "@/libs/utils/icons";
import { getURL } from '@/libs/utils/urls';
import { hasPermission } from "@/libs/utils/user";
import service_others from '@/services/others';

export default {
  name: "SidebarSearchNew",
  components: {
    BSidebar,
    BRow,
    BCol,
    BAvatar,
    BAvatarGroup,
    BBadge,
    BImg,
    BButton,
    BButtonGroup,
    BSpinner,
    BSkeleton,
    BDropdown,
    BDropdownHeader,
    ProfileCrecimiento: () => import('@/views/pages/profile/ProfileCrecimiento.vue'),
    scaleRateSearch: () => import('@/views/components/graph/scaleRateSearch.vue'),
  },
  props: {
    open_sidebar: {
      type: Boolean
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    network: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      getFormat,
      checkNumber,
      getCategoryIconSearcher,
      getIconInterest,
      separatebycomma,
      show: this.open_sidebar,
      lists: [],
      campaigns: [],
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      update_image: false,
      data_user: null, 
      error_data: null,
      showing_post_data: true,
      showing_post_data_prices: true,
      subscribers_growth_prc_graph: null,
      mentions_user: null,
      mentions_avatares: [],
      more_info: [this.$t('search.interestAudience'), this.$t('search_preview.qualityAudience'), this.$t('instagram.adPerformance'), this.$t('search_preview.similarInfluencers'), this.$t('preview.audience_age_gender'), this.$t('search_preview.analysisHash'), this.$t('search_preview.analysisContent'), `...${this.$t('search_preview.more20')}`],
      loaded_service: false,
      states_inform: {has_bought: false, has_expired: false, valid_profile: 0},
      loaded_service_add: false,
      show_add_to: {list: false, campaign: false},
    }
  },
  created() {
    this.getData();
  },
  computed: {
    host() {
      return window.location.host;
    }
  },
  methods: {
    mentionsCase(post_frequency) {
      let traduction = '';
      switch (true) {
      case post_frequency > 21:
        traduction = 'mentions_text_mark.frequency'
        break;
      case post_frequency > 14:
        traduction = 'mentions_text_mark.optimal_above'
        break;
      case post_frequency > 6:
        traduction = 'mentions_text_mark.optimal'
        break;
      case post_frequency > 4:
        traduction = 'mentions_text_mark.below_optimal'
        break;
      default:
        traduction = 'mentions_text_mark.not_frequency'
        break;
      }
      return traduction;
    },
    redirectToSocialNet(username) {
      window.open(getURL(this.network, username), '_blank')
    },
    getImagesContent() {
      if (this.data_user.features.last_media?.data && Object.keys(this.data_user.features.last_media.data).length > 0) {
        const array_images = []
        for (const image_objet in this.data_user.features.last_media.data) {
          array_images.push(this.data_user.features.last_media.data[image_objet])
        }
        return array_images.slice(0, 5);
      }
      return []
    },
    getData() {
      const username = this.data.username.name.username;
      getSummaryInfluencer(this.network, username).then((response) => {

        if (response.error) {
          this.error_data = response.error;
          this.data_user = {};
          
        } else {
          this.data_user = response.report;
          this.mentions_user = !Array.isArray(this.data_user.features.blogger_mentions) ? this.data_user.features.blogger_mentions.data.mentions.performance["180d"] : null;
          if (this.mentions_user) {
            for (const user in this.mentions_user.users) {
              this.mentions_avatares.push(this.mentions_user.users[user].avatar_url)
            }
            this.mentions_avatares = this.mentions_avatares.slice(0, this.data_user.features.blogger_mentions.data.reports.length)
          }
        }
        this.data_user.parent_data = this.data;
        this.loaded_service = true;
      })

      getHistorySuscribers(this.network, username).then((response) => {
      
        if (!Array.isArray(response.metrics)) {
          this.subscribers_growth_prc_graph = []
          response.metrics.followers_count.history.forEach(item => {
            const date = new Date(item.time_iso);
            const time_stamp_in_secondes = Math.floor(date.getTime() / 1000);
            this.subscribers_growth_prc_graph.push({count: item.value, date: time_stamp_in_secondes});
          });
        }
      })

      service_others.getVisitedProfiles(this.network, username).then((response) => {
        const user = response.results.find(i => i.username === username)
        if (user) {
          this.states_inform.has_bought = true;
          this.states_inform.has_expired = !user.state;
          this.states_inform.valid_profile = user.valid_profile;
        }
      })
    },
    getProfileUrl() {
      if (hasPermission('view_profile')) {
        const alias = this.data.username.name.username;
        this.$router.push({name: 'auth-perfil', params: {network: this.network, alias}})
      }
    },
    errorLoad(content) {
      content.basic.preview_url = this.backgroundDefault;
      this.update_image = !this.update_image;
      return this.backgroundDefault;
    },
    addToCampaign(campaign_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
      this.$emit('add_to_campaign', this.data, campaign_uuid);
      setTimeout(() => {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
        this.campaigns.find((item) => item.uuid === campaign_uuid).with_influencer = true;
      }, 500);
    },
    addToList(list_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.remove('d-none');

      lists_services.bulkAddInfluencer(list_uuid, {influencers: [this.getFormatAddInfluencer()]}).then(() => {
        setTimeout(() => {
          this.lists.find((item) => item.uuid === list_uuid).with_influencer = true;
          this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.add('d-none');
        }, 500)
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: "top-right",
            props: {
              title:  this.$t('lists.addinfluencer'),
              icon: "CheckCircleIcon",
              variant: "success",
              text: this.$t('lists.addlist')
            }
          })
        })
      })
    },
    getInfluenerInLists() {      
      search_services.getInfluencerIn(this.data.username.name.username, 'instagram' , 'lists').then((response) => {
        this.lists = response.results
        this.loaded_service_add = true;
      });
      
    },
    getInfluencersInCampaign() {
      search_services.getInfluencerIn(this.data.username.name.username, 'instagram', 'campaign').then((response) => {
        this.campaigns = response.results;
        this.loaded_service_add = true;
      });
    },
    getFormatAddInfluencer() {
      return {
        influencer: this.data.username.name.username,
        network:this.network,
        followers: this.data.followers,
        image: this.data.username.avatar
      }
    },
    hasClosedSidebar() {
      this.$refs.sidebar_search.$el.classList.remove('open-sidebar-search')
    },
  },
  watch: {
    'show_add_to.list'(value) {
      if (value) {
        this.getInfluenerInLists();
      }

    },
    'show_add_to.campaign'(value) {
      if (value) {
        this.getInfluencersInCampaign();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.overflow-none {
  height: auto;
  min-height: 100%;
}
.spinner-dropdown-item {
  position: absolute;
  right: 19px;
  background-color: white;
  height: 16px;
  width: 16px;
  color: #ccc;
}
.container-options-list-ul {
  max-height: 300px;
  overflow-y: scroll;
}
.text-muted-left-side-analytics {
  font-size: 14px;
  font-family: 'avenir-medium';
  color: #718096;
  margin-bottom: 0.3em;
}
.border-bottom-sidebar {
  position: sticky;
  height: fit-content;
  top: 15px;
}
.background-small {
  background-color: #F5F8FA;
  overflow-y: scroll;
  border-left: 1px solid #e2e8f0;
}
.text-underline {
  text-decoration: underline;
}
.badge-nivel {
  font-family: 'avenir-medium' !important;
  font-size: 14px;
  margin-left: 0.5em;
  line-height: 1.3;
  font-weight: 500;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  top: 0px;
  left: 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .grid-item {
    .container-icon-heart {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 45%;
      background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      display: flex;
      align-items: end;
      p {
        margin-left: 1em;
        color: white;
        font-family: 'avenir-medium';
      }
    }
    background-color: white;
    position: relative;
    .img-cover {
      width: 100%;
      height: 135.5px;
      object-fit: cover;
    }
    &:nth-child(1) {
      grid-column-start:1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      
      .img-cover {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 281px;
      }
    }
    &:nth-child(3) {
      .img-cover {
        border-top-right-radius: 6px;
      }
    }
    &:nth-child(5) {
      .img-cover {
        border-bottom-right-radius: 6px;
      }
    }
  }
}
.mr-05 {
  margin-right: 0.2em;
}
.mb-02 {
  margin-bottom: 0.4em;
}
.min-height-281 {
  min-height: 281px;
}
.min-height-116 {
  min-height: 116px;
}
.min-height-321 {
  height: 321px;
  min-height: 321px;
}
.min-height-232 {
  height: 232px;
  min-height: 232px;
}
.f-12 {
  font-size: 12px;
}
.box-expired-report {
  background-color: white;
  padding: 36px 0px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #E2E8F0;
  .grid-inner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .inner-box {
      border: 1px solid #E2E8F0;
      border-radius: 6px;
      box-shadow: 0 1px 4px #0b14260a;
      padding: 16px;
    }
  }
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;

  }
}
.icon-arrows-prices {
  display: block;
  flex-shrink: 0;
  color: #687385;
  background-color: #F5F8FA;
  border-radius: 100%;
  margin-right: 0.5em;
  margin-top: 0.2em;
}
.grid-analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .title-result {
    font-size: 18px;
    width: 100%;
    display: block;
  }
}
.outline-secondary-custom-analysis {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  padding: 10px;
}
.grid-categories {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  .img-grid-categorie {
    width: 24px;
    height: 24px;
  }
  .icon-b {
    width: 24px;
    height: 24px;
  }
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.block-emtpy-data {
  background-color: #eff3f6;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  .container-icon-lock {
    background-color: #0096fe;
    padding: 1em;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

  }
  .icon-lock {
    color: white;
    width: 30px;
    height: 30px;
  }
}
.toggle-button-post-history {
  background-color: #F5F8FA;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #E2E8F0;

  button {
    background: none !important;
    color: #687385 !important;
    box-shadow: none !important;
    padding: 9px !important;
    border: 1px solid transparent !important;
  }
  .active-button {
    background-color: white !important;
    border: 1px solid #E2E8F0 !important;
    color: #404452
  }
}

.backgroud-gray-light-avatar {
  background-color: #eff3f6;
  color: #687385;
}
.color-gray-title-add {
  color: #687385;
  font-size: 12px;
}
.f-16 {
  font-size: 16px;
}
</style>
<style lang="scss">
.dropdown-add-influencer-action {
  .dropdown-header {
    padding: 0px;

    .button-group-add-influencer-action {
      button {
        background-color: white !important;
        border: none !important;
        color: #404452 !important;
        display: flex;
        justify-content: space-between;
        padding: 10px 12px 10px 20px !important;
        font-family: 'avenir-medium' !important;
        &:hover {
          background-color: #F5F8FA !important;
          box-shadow: none !important;
        }

        .img-icon-action {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
.sidebar-search-new-version > .b-sidebar {
  width: 910px !important;
}
.avatar-sidebar-search {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100%;
}
.sidebar-search-new-version .bg-light{
  background-color: white !important;
}
.sidebar-search-new-version .b-sidebar-header {
  padding: 1em 0;
  font-size: 13px !important;
  border-bottom: 1px solid #e2e8f0;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.container-thubnail {
  position: relative;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 1em;
}
.thubnail-contents-sidebar {
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 1em !important;
  display: block;
}
.container-thubnail:hover {
  cursor: pointer;
}
.container-thubnail:hover .container-text-sidebar {
  animation: animation-text 400ms;
  display: block;
}
.container-reations-sidebar {
  background-color: white;
  height: 50px;
  width: 280px;
  position: absolute;
  bottom: 0em;
  border-radius: 0.6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 0 0.5em;
}
.container-text-sidebar {
  background: rgb(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  top: 0;
  height: 240px;
  padding: 1em;
  width: 280px;
  border-radius: 1em;
  display: none;
  overflow: hidden;
  font-size: 13px;
}
.padding-right-01 {
  padding-right: 0.2em;
}
.spinner-searcher-sidebar {
  width: 4em;
  height: 4em;
  color: gray;
  margin: 15em 0 0 0;
}
.margin-category-badge {
  margin: 0.3em 0.3em 0.3em 0;
}
.container-drop-items {
  // width: 250px;
  width: 100%;
}
.container-drop-items > a:hover {
  background-color: #ccc !important;
}
.secondary-color-icon {
  color: #718096;
}
.success-color-icon {
  color: green;
}
.outline-blue-hover .btn-group {
  border: none !important;
}
.outline-blue-hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #0096fe;
  color: #0096fe;
  padding: 11px 21px;
  border-radius: 0.5em;
}
.outline-blue-hover:hover {
  background-color: #0096fe;
  color: white;
  transition: all 300ms;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-color > button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

@keyframes animation-text {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
